/*
 * UPPY WIDGET
 * */

.uppy-Dashboard-poweredBy {
	display: none !important;
}

.uppy-Dashboard-inner {
	margin: auto;
	width: 100% !important;
	height: 100% !important;
	border: none;
	border-radius: 0;
	background: white;
	max-height: 565px;
	min-height: 266px !important;
}

@media screen and (min-width: 30em) {
	.uppy-Dashboard-inner {
		height: calc(100vh - 382px) !important;
		min-height: 266px !important;
	}
}

@media screen and (min-width: 48em) {
	.uppy-Dashboard-inner {
		border: 1px solid #eaeaea;
		border-radius: 6px;
		width: 750px !important;
		height: calc(100vh - 382px) !important;
		min-height: 266px !important;
	}
}

@media screen and (min-width: 62em) {
	.uppy-Dashboard-inner {
		height: calc(100vh - 280px) !important;
		min-height: 366px !important;
	}

	.uppy-Dashboard-innerWrap {
		max-height: calc(100vh - 80px);
		min-height: 350px;
	}
}

.uppy-Dashboard-AddFiles {
	min-height: 350px;
}

.uppy-Dashboard-innerWrap span {
	margin: 0;
}
.uppy-Dashboard-AddFiles-list span {
	margin: 0;
	margin-bottom: 10px;
}
.uppy-DashboardTab {
	max-height: 80px;
}
.uppy-Dashboard-AddFiles-title {
	text-align: center;
}
.uppy-DashboardItem-action--copyLink {
	display: none;
}

.uppy-Dashboard-browse {
	border-radius: 25px;
	background-color: #0f66d0 !important;
	color: white;
	cursor: pointer;
	display: block;
	text-align: center;
	font-weight: 700 !important;
	margin: 16px auto;
	padding: 16px 24px;
	outline: none;
	min-height: 38px;
	box-sizing: border-box;
	transition: all 0.3s ease;
}

.uppy-Dashboard-browse:hover {
	background-color: #1478f2;
}

.uppy-Dashboard-browse:hover {
	border: none;
	background-color: #027be5;
}
.uppy-size--md .uppy-Dashboard-AddFiles-title {
	font-size: 20px;
}

.uppy-Dashboard-AddFiles-title {
	font-family: 'Source Sans Pro', sans-serif;
	font-size: 16px;
	margin: 16px 0 0;
}

div.uppy-Dashboard-AddFiles-list {
	overflow-y: scroll;
	overflow-x: hidden;
}

.uppy-Dashboard-browse,
.uppy-u-reset .uppy-Dashboard-browse {
	background-color: #0f66d0;
	border: none;
	font-size: 1rem;
	line-height: 1rem;
	font-weight: 700;
	letter-spacing: 0.75px;
	text-transform: capitalize;
	min-height: 48px;
	padding: 16px 48px;
	box-shadow: 0px 2px 5px rgba(38, 43, 48, 0.4);
	font-family: 'Source Sans Pro', sans-serif;
}

@media screen and (min-width: 30em) {
	.uppy-Dashboard-browse,
	.uppy-u-reset .uppy-Dashboard-browse {
		background-image: url(assets/images/icons/icon__videocam.svg);
		background-position: 20px center;
		background-size: 24px;
		background-repeat: no-repeat;
	}
}

.uppy-DashboardTab-btn,
[dir='ltr'] .uppy-size--md .uppy-DashboardTab-btn {
	height: 96px;
	justify-content: space-evenly;
	margin: 0 0.25rem;
}

@media screen and (max-width: 30em) {
	.uppy-Dashboard-AddFiles-title {
		display: none;
	}
	.uppy-DashboardTab-btn {
		height: 100%;
		justify-content: left;
	}
	[dir='ltr'] .uppy-DashboardTab-btn svg {
		height: 18px;
	}
}

.uppy-DashboardTab-inner {
	background-color: transparent;
	border-radius: 0;
	box-shadow: none;
}

.uppy-Dashboard-browse:hover,
.uppy-u-reset .uppy-Dashboard-browse:hover {
	background-color: #1478f2;
}

.uppy-DashboardTab svg {
	transform: scale(2);
	height: 32px;
}

.uppy-Dashboard-Item-previewInnerWrap {
	background-color: #0f66d0 !important;
}

.uppy-Dashboard-Item-previewInnerWrap svg path {
	fill: #0f66d0;
}

.uppy-Webcam-permissonsIcon svg {
	fill: #0f66d0;
}

.uppy-DashboardContent-back {
	color: #0f66d0;
}
.uppy-StatusBar-actions {
	background-color: transparent;
}

.uppy-StatusBar-actions .uppy-StatusBar-actionBtn--upload {
	display: inline-block;
	margin-left: auto;
	margin-right: auto;
	float: none;
	border-radius: 25px;
	background-color: #0f66d0 !important;
	color: #fff;
	cursor: pointer;
	display: block;
	font-size: 1rem;
	line-height: 1rem;
	font-weight: 700;
	font-family: 'Source Sans Pro', sans-serif;
	letter-spacing: 0.05rem;
	text-transform: capitalize;
	padding: 16px 48px;
	outline: none;
	min-height: 48px;
	max-width: 320px;
	min-width: 240px;
	-webkit-transition: all 0.3s ease 0ms;
	transition: all 0.3s ease 0ms;
	border-top: 2px solid transparent;
	border-right: 2px solid transparent;
	border-bottom: 2px solid transparent;
	border-left: 2px solid transparent;
	box-sizing: border-box;
	box-shadow: 0px 2px 5px rgba(38, 43, 48, 0.4);
}

.uppy-StatusBar-actions .uppy-StatusBar-actionBtn--upload:hover {
	background-color: #1478f2 !important;
}

.uppy-DashboardTab-btn:hover .uppy-DashboardTab-name:after {
	background-color: #0f66d0;
}

.uppy-Webcam-buttonContainer,
.uppy-Audio-buttonContainer {
	display: flex;
	flex-direction: row-reverse;
	padding: 0;
	background-color: #f6f6f8;
	border: 0;
	z-index: 1;
	position: relative;
	width: fit-content;
	margin: auto;
}

.uppy-size--lg .uppy-Webcam-buttonContainer {
	width: fit-content;
	margin-left: auto;
}

.uppy-size--lg .uppy-Audio-buttonContainer {
	justify-content: center;
}

.uppy-Audio-permissonsIcon svg g rect {
	fill: #0187ff;
}

.uppy-Webcam-button svg,
.uppy-Audio-button svg {
	width: 36px;
	height: 36px;
	fill: #cc0033;
}

.uppy-Webcam-button svg path,
.uppy-Audio-button svg path {
	fill: #cc0033;
}

.uppy-Webcam-button,
.uppy-Audio-button {
	margin: 0 8px;
	border-radius: 50px;
	height: 48px;
	width: 48px;
	background-color: #cde7fe;
}

.uppy-Webcam-button[aria-label~='Discard'],
.uppy-Audio-button[aria-label~='Discard'] {
	background-color: #cc0033;
	min-width: 7rem;
	width: fit-content;
	padding: 4px 16px;
}

.uppy-Webcam-button[aria-label~='Discard'] svg,
.uppy-Audio-button[aria-label~='Discard'] svg {
	display: none;
}

.uppy-Webcam-button[aria-label~='Discard']:after,
.uppy-Audio-button[aria-label~='Discard']:after {
	content: 'Retake';
	font-weight: 600;
	font-family: 'Source Sans Pro', sans-serif;
}

.uppy-Webcam-button:hover svg,
.uppy-Audio-button:hover svg {
	fill: #e20039;
}

.uppy-Webcam-button[aria-label~='Discard']:hover,
.uppy-Audio-button[aria-label~='Discard']:hover {
	background-color: #e20039;
}

.uppy-Webcam-button--submit,
.uppy-Audio-button--submit {
	flex-direction: reversed;
	min-width: 7rem;
	width: fit-content;
	background: #0f66d0;
	border-radius: 30px;
	border: none;
	padding: 4px 16px;
	display: inline-flex;
	align-items: center;
	margin-left: 300px;
}

@media screen and (min-width: 10em) {
	.uppy-Webcam-button--submit,
	.uppy-Audio-button--submit {
		margin: auto;
	}
}

.uppy-Webcam-button--submit svg,
.uppy-Audio-button--submit svg {
	display: none;
}

.uppy-Webcam-button--submit:hover,
.uppy-Audio-button--submit:hover {
	background: #1478f2;
}

.uppy-Webcam-button--submit:after,
.uppy-Audio-button--submit:after {
	font-weight: 600;
	font-family: 'Source Sans Pro', sans-serif;
}

.uppy-Webcam-button--submit:after {
	content: 'Submit Video';
}

.uppy-Audio-button--submit:after {
	content: 'Submit Audio';
}

.uppy-Webcam-recordingLength,
.uppy-Audio-recordingLength {
	position: absolute;
	left: 60px;
	bottom: 42px;
	color: #47596a;
	font-size: 20px;
	width: 104px;
	text-align: right;
	z-index: 2;
}

.uppy-Audio-recordingLength {
	left: 170px;
}

.uppy-Webcam-recordingLength span,
.uppy-Audio-recordingLength span {
	color: #47596a;
	font-family: 'Source Sans Pro', sans-serif;
	font-weight: 600;
	font-size: 20px;
}

.uppy-Webcam-recordingLength span[aria-label='Recording length 0:00'],
.uppy-Audio-recordingLength span[aria-label='Recording length 0:00'] {
	color: transparent;
}

.uppy-Webcam-recordingLength span[aria-label='Recording length 0:00']:after,
.uppy-Audio-recordingLength span[aria-label='Recording length 0:00']:after {
	content: 'Start →';
	color: #47596a;
	font-size: 16px;
	font-weight: 600;
	font-family: 'Source Sans Pro', sans-serif;
	left: 10px;
	position: relative;
}

.uppy-Webcam-recordingLength span[aria-label='Recording length 0:00']:before,
.uppy-Audio-recordingLength span[aria-label='Recording length 0:00']:before {
	display: none;
}

.uppy-Webcam-footer,
.uppy-Audio-footer {
	width: 100%;
	/* display: block; */
}

.uppy-size--lg .uppy-Webcam-videoSourceContainer {
	width: auto;
	max-width: 33%;
}

.uppy-Audio-player {
	margin: auto;
	/* margin-top: 25%; */
	width: inherit;
	max-width: 90%;
}

/* @media screen and (min-width: 30em) {
	.uppy-Audio-player {
		margin-top: 35%;
	}
}

.uppy-Audio-player {
	margin-top: 35%;
} */

.uppy-Informer span {
	line-height: 12px;
	width: 13px;
	height: 13px;
	display: inline-table;
	vertical-align: middle;
	color: #525252;
	background-color: transparent;
	border-radius: 50%;
	position: relative;
	top: -1px;
	left: 3px;
	font-size: 10px;
	margin-left: -1px;
}

.uppy-Informer span:hover {
	cursor: help;
}

.uppy-Informer span:after {
	line-height: 1.3;
	word-wrap: break-word;
}

.uppy-Informer p {
	max-width: 500px;
	padding: 8px;
	min-width: 360px;
}
